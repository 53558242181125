html
{
  background-color: #050607;
}
@media screen and (min-width: 600px) {
  .mobile-break {
    display: none;
  }

  .body {
    background: url("../assets/G.svg") no-repeat center center ;
    background-size: cover;
    background-position-y: -2vh;
  }
  .body2 {
    background: url("../assets/bg2.svg") no-repeat center center;
    background-size: cover;
    background-position-y: 9rem;
  }
  .body3 {
    background: url("../assets/bg3.svg") no-repeat center center;
    background-size: cover;
  }

}

@media screen and (max-width: 768px) {
  .laptop-break {
    display: none;
  }
  .body {
    background: url("../assets/bgmob1.svg") no-repeat center center;
    background-size: cover;
    background-position-y: 3rem;
    border: none;
  }
  .body2 {
    background: url("../assets/G.svg") no-repeat center center;
    background-size: cover;
    background-position-y: -5rem;
  }
  .body3 {
    background: url("../assets/bgmob3.svg") no-repeat center center;
    background-size: cover;
  }

  /* .touch {
    background: url("../assets/touch.png") no-repeat center center;
    background-size: cover; */
  /* } */
}
